/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gamepad': {
    width: 18,
    height: 10,
    viewBox: '0 0 18 10',
    data: '<path pid="0" d="M4.91-.001h8.18c1.303 0 2.552.527 3.472 1.464A5.047 5.047 0 0118 5a5.047 5.047 0 01-1.438 3.536 4.845 4.845 0 01-7.129-.202h-.867a4.845 4.845 0 01-7.128.202A5.047 5.047 0 010 4.999c0-1.326.517-2.598 1.438-3.536A4.864 4.864 0 014.909 0zm-.82 2.5v1.667H2.456v1.666H4.09V7.5h1.636V5.832h1.637V4.166H5.727V2.499H4.091zm7.774 2.5c-.326 0-.638.132-.868.366a1.262 1.262 0 000 1.768 1.216 1.216 0 001.736 0c.23-.235.359-.552.359-.884 0-.331-.13-.65-.36-.884A1.216 1.216 0 0011.864 5zm2.454-2.5c-.325 0-.637.132-.868.366a1.262 1.262 0 000 1.768c.23.234.543.366.868.366.326 0 .638-.132.868-.366a1.262 1.262 0 000-1.768 1.216 1.216 0 00-.868-.366z" _fill="#898B9B"/>'
  }
})
